import { defineAbility } from '@casl/ability'
import store from '@store'
import isObject from '@utils/isObject'

export const useAbility = () => defineAbility(can => {
  const { permissions } = store.state.user

  Object.keys(permissions).map(action => permissions[action].map(object => {
    // No fields in object
    if (!isObject(object)) return can(action, object)

    // Fields in object
    return Object.keys(object).map(item => can(action, item, object[item]))
  }))
})

export const _ = null
